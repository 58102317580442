<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Client Properties
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        @click="addPerson()"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Person</span>
      </button> -->
    </div>
    <div v-if="viewState == 'Idle'">
      <div v-if="client.is_activated" class="grid gap-5">
        <div class="border rounded-lg p-5">
          <h4 class="uppercase font-semibold text-lg tracking-wide">
            Software Info
          </h4>
          <div class="grid sm:grid-cols-1 lg:grid-cols-3 py-3 gap-10">
            <div
              class="flex flex-col w-full"
              v-for="(value, key) in softwareInfo"
              :key="key"
            >
              <label class="uppercase font-semibold text-sm">{{
                getTitleCase(key)
              }}</label>
              <div class="border border-gray-400 bg-gray-100 py-2 px-4 rounded">
                {{ value }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="border rounded-lg p-5"
          v-for="(value, key) in installedEquipment"
          :key="key"
        >
          <h4 class="uppercase text-lg font-semibold tracking-wide">
            {{ getTitleCase(key) }}
          </h4>
          <div class="grid sm:grid-cols-1 lg:grid-cols-3 py-3 gap-10">
            <div
              class="flex flex-col w-full"
              v-for="(value, key) in value"
              :key="key"
            >
              <label class="uppercase font-semibold text-sm">{{
                getTitleCase(key)
              }}</label>
              <div class="border border-gray-400 bg-gray-100 py-2 px-4 rounded">
                {{ value }}
              </div>
            </div>
          </div>
        </div>
        <p class="text-gray-700 text-sm font-semibold">
          Last Updated:
          {{ $moment(lastUpdatedDate).format("DD/MM/YYYY [at] HH:mm") }}
        </p>
      </div>
      <div v-else class="flex flex-wrap">
        <p class="mx-auto my-16">
          This client is not activated. Once activated, this panel will become
          available.
        </p>
      </div>
    </div>
    <div
      v-else-if="viewState == 'Loading'"
      class="flex w-full justify-center py-20"
    >
      <Spinner :color="'text-black'" :size="10" />
    </div>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const Panel = () => import("@/components/Panel.vue");
const Spinner = () => import("@/components/Spinner.vue");
import { toTitleCase } from "@/utils/titleCase";

export default {
  name: "ClientProperties",
  components: {
    Notification,
    Panel,
    Spinner,
  },
  props: {
    // people: Array,
    // contactId: String,
    client: Object,
  },
  async mounted() {
    await this.getClientProperties();
  },
  data() {
    return {
      viewState: "Idle",
      softwareInfo: null,
      installedEquipment: null,
      lastUpdatedDate: null,
    };
  },
  methods: {
    async getClientProperties() {
      if (!this.client.is_activated) 
        return;

      this.viewState = "Loading";
      this.installedEquipment = {};

      const data = await this.iVClientService.getClientDeviceProperties(
        this.client.client_id
      );

      this.softwareInfo = data.softwareInfo;
      if (data.installedEquipment) {
        for (const [key, value] of Object.entries(data.installedEquipment)) {
          const k = toTitleCase(key);
          this.installedEquipment[k] = value;
  
          if (value.properties) {
            for (const [key2, value2] of Object.entries(value.properties)) {
              this.installedEquipment[k][key2] = value2;
            }
          }
  
          if (value.equipmentMetadata) {
            for (const [key2, value2] of Object.entries(
              value.equipmentMetadata
            )) {
              this.installedEquipment[k][key2] = value2;
            }
          }
  
          delete this.installedEquipment[k].properties;
          delete this.installedEquipment[k].equipmentMetadata;
        }
      }

      if (this.installedEquipment) {
        // Had to do this because when the value of other fields, such as name, are just a number,  it passes as valid unix dates.
        const DATE_FIELDS = [
          "createdAt",
          "updatedAt",
          "installedAt",
          "removedAt",
          "calibrationAt",
          "calibrationExpiresAt",
          "lastConnectedAt",
        ];
  
        for (const [key, value] of Object.entries(this.installedEquipment)) {
          for (const [key2, value2] of Object.entries(value)) {
            if (DATE_FIELDS.includes(key2)) {
              this.installedEquipment[key][key2] = this.$moment(value2).format(
                "DD/MM/YYYY [at] HH:mm"
              );
            }
          }
        }
      }

      this.lastUpdatedDate = data.$metadata.$lastUpdated;
      this.viewState = "Idle";
    },
    getTitleCase(string) {
      return toTitleCase(string);
    },
  },
};
</script>
