<template>
  <form @submit.prevent="onSendMessage" class="relative">
    <!-- Sender -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm">
        Sender
        <span class="text-red-500">*</span></label
      >
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="e.g. Customer Support HQ"
        required
        v-model="message.sender"
        :disabled="isMessageSending"
      />
    </div>
    <!-- /Sender -->

    <!-- Subject -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm">
        Subject
        <span class="text-red-500">*</span></label
      >
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="e.g. [Action Required] Regarding work order #12345"
        required
        v-model="message.subject"
        :disabled="isMessageSending"
      />
    </div>
    <!-- /Subject -->

    <!-- Message -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm">
        Message
        <span class="text-red-500">*</span></label
      >
      <textarea
        class="w-full bge-input bge-input-spacing rounded h-48"
        placeholder="e.g. I hope this finds you well. An issue has arisen ..."
        required
        v-model="message.body"
        :disabled="isMessageSending"
      />
    </div>
    <!-- /Message -->

    <div class="flex justify-end mt-5 gap-4 items-center">
      <button
        type="submit"
        class="relative overflow-hidden bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        :disabled="isMessageSending"
      >
        <div class="flex items-center gap-2">
          <Spinner v-if="isMessageSending" :color="'#ccc'" :size="4" />
          <span v-else class="pi pi-send"></span>
          <span>Send Message</span>
        </div>
      </button>
    </div>
  </form>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";

const Spinner = () => import("@/components/Spinner.vue");

export default {
  name: "SendClientMessage",
  components: {
    Spinner,
  },
  mixins: [notificationMixin],
  props: {
    client: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      message: {
        sender: "",
        subject: "",
        body: "",
      },
      isMessageSending: false,
    };
  },
  async mounted() {
    const userData = this.AuthService.getUserInfo();
    this.message.sender = `${userData.name} (${userData.email})`;
  },
  methods: {
    async onSendMessage() {
      try {
        this.isMessageSending = true;
        await this.iVClientService.sendDeviceMessage(
          this.client.client_id,
          this.message,
        );
        this.clearFormFields();
        this.notifySuccess("Message sent");
        this.$emit("message-sent");
      } catch (error) {
        this.notifyError("Failed to send message");
      } finally {
        this.isMessageSending = false;
      }
    },
    clearFormFields() {
      this.message.subject = "";
      this.message.body = "";
    },
  },
  emits: ["message-sent"],
};
</script>
