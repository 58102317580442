<template>
  <form @submit.prevent="saveClientDetails" class="flex flex-wrap py-4">
    <div
      v-show="viewState == 'Loading'"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <Spinner :color="'text-black'" :size="10" />
    </div>
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Equipment<span class="text-red-500">*</span></label
      >
      <MultiSelect
        v-if="options"
        :options="options"
        v-model="selectedEquipmentCodes"
      />
    </div>
    <!-- <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
        v-model="contactDetails.name"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Company Name..."
      />
    </div>
    <div class="w-full py-2">
      <div class="border rounded bg-gray-100 border-gray-400 py-2 px-4">
        <Toggle
          labelPosition="left"
          label="Supplier?"
          labelClasses="uppercase font-semibold text-sm"
          :checked="contactDetails.is_supplier"
          @value-changed="(value) => (contactDetails.is_supplier = value)"
        />
      </div>
    </div>
    <div class="w-full py-2">
      <div class="border rounded bg-gray-100 border-gray-400 py-2 px-4">
        <Toggle
          labelPosition="left"
          label="Agent?"
          labelClasses="uppercase font-semibold text-sm"
          :checked="contactDetails.is_agent"
          @value-changed="(value) => (contactDetails.is_agent = value)"
        />
      </div>
    </div>
    <div class="w-full py-2">
      <div class="border rounded bg-gray-100 border-gray-400 py-2 px-4">
        <Toggle
          labelPosition="left"
          label="Customer?"
          labelClasses="uppercase font-semibold text-sm"
          :checked="contactDetails.is_customer"
          @value-changed="(value) => (contactDetails.is_customer = value)"
        />
      </div>
    </div> -->
    <div class="w-full">
      <button
        type="button"
        @click="saveClientDetails"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save Client Details</span>
      </button>
    </div>
  </form>
</template>

<script>
const Toggle = () => import("@/components/Toggle.vue");
const Spinner = () => import("@/components/Spinner.vue");
const MultiSelect = () => import("@/components/MultiSelect.vue");

export default {
  name: "EditContactDetails",
  components: {
    Toggle,
    Spinner,
    MultiSelect,
  },
  props: {
    client: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      options: null,
      viewState: "Idle",
      selectedEquipmentCodes: [],
    };
  },
  async mounted() {
    this.viewState = "Loading";

    const selectedCodes = this.client.metadata.activated_product_codes;
    const data = await this.ProductService.getProductCodeDataFromConfig();

    for (const [key, value] of Object.entries(data)) {
      if (value.codes.every((v) => selectedCodes.includes(v))) {
        this.selectedEquipmentCodes.push(key);
      }
    }

    let newOptions = [];
    Object.entries(data).map(([key, value]) => {
      newOptions.push({
        value: key,
        text: value.title,
      });
    });
    this.options = newOptions;
    this.viewState = "Idle";
  },
  methods: {
    saveClientDetails: async function () {
      let activatedProductCodes = [];

      const data = await this.ProductService.getProductCodeDataFromConfig();

      this.selectedEquipmentCodes.map((code) => {
        if (data[code]) {
          activatedProductCodes.push(...data[code].codes);
        }
      });

      await this.iVClientService.setIvyActivationsProductCodes(
        this.client.client_id,
        activatedProductCodes
      );

      this.$emit("complete");
    },
  },
};
</script>
